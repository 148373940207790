<template>
    <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                    Data export - Completed online exams
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  Data export - Completed online exams
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="3">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                      >
                        <template v-slot:label>
                          View from date
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        outlined
                        dense
                        no-title
                        @input="menu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                    ref="menuEndDate"
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                      >
                        <template v-slot:label>
                          View up to date
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.end_date"
                        outlined
                        :disabled="search.start_date == '' || search.start_date == null "
                        :min="search.start_date"
                        dense
                        no-title
                        @input="menuEndDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="d-flex" v-if="currentUser.access_type =='federal'" cols="12" sm="12" md="3">
                  <v-select 
                    :items="scores" 
                    v-model="search.score_id" 
                    label="SCORE"
                    item-text="name" 
                    item-value="id" 
                    v-on:keyup.enter="searchOnlineExamReports()"
                    @input="search.score_id = $event !== null ? $event : ''" 
                    outlined 
                    clearable
                    dense
                  ></v-select>
                </v-col>
                
                <v-col class="d-flex" cols="12" sm="12" md="3">
                  <v-select 
                    :items="months" 
                    v-model="search.month" 
                    label="Select Month" 
                    item-text="name" 
                    item-value="value" 
                    v-on:keyup.enter="searchOnlineExamReports()"
                    @input="search.month = $event !== null ? $event : ''" 
                    outlined 
                    clearable
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="2">
                  <v-btn 
                    @click="searchOnlineExamReports()" 
                    :loading="loading" 
                    class="mt-1 btn btn-primary text-white" 
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <v-row>
                <v-col class="d-flex justify-content-end mt-1 mb-2" cols="12" md="">
                    <v-btn 
                        :loading="isExporting" 
                        color="success" 
                        class="ml-4"
                        @click="exportToCsv()"
                    >
                        <i class="fas fa-file-csv text-white"></i> &nbsp; Export To CSV
                    </v-btn>

                    <v-btn 
                        :loading="isExportingExcel" 
                        color="orange" 
                        @click="exportToExcel()" 
                        class="ml-4 text-white"
                    >
                        <i class="fas fa-file-excel text-white"></i> &nbsp;Export To Excel
                    </v-btn>
                </v-col>
            </v-row>
        
            <div class="table-responsive">
              <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Enrolment key</th>
                  <th class="px-3">Candidate Name</th>
                  <th class="px-3">Exam Date</th>
                  <th class="px-3">Purchased Date</th>
                  <th class="px-3">Expiry date </th>
                  <th class="px-3">Email </th>
                  <th class="px-3">Accountholder Name </th>
                  <th class="px-3">Subject Name </th>
                  <th class="px-3">Subject Code </th>
                  <th class="px-3">Mark Obtained </th>
                  <th class="px-3">Instrument </th>
                  <th class="px-3">Syllabus </th>
                  <th class="px-3">Grade </th>
                  <th class="px-3">Score </th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item, index) in online_exam_reports" :key="index">
                    <td class="px-2">
                      <span class="px-2">
                        {{ item.candidate_exam_key ? item.candidate_exam_key : '-' }}
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="px-2">
                        {{ item.candidate_name ? item.candidate_name : '-' }}
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="px-2">
                        {{ item.exam_date ? item.exam_date : '-' }}
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="px-2">
                        {{ item.purchase_date ? item.purchase_date : '-' }}
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="px-2">
                          {{ item.expiry_date ? item.expiry_date : '-' }}
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="px-2">
                          {{item.candidate_email ? item.candidate_email : '-'}}
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="px-2">
                          {{item.account_holder_name ? item.account_holder_name : '-'}} 
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="">
                          {{item.subject_name ? item.subject_name : '-'}} 
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="px-2">
                          {{item.subject_code ? item.subject_code : '-'}} 
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="px-2">
                          {{item.mark_obtained ? item.mark_obtained : '-'}} 
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="px-2">
                          {{item.instrument_name ? item.instrument_name : '-'}} 
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="px-2">
                          {{item.syllabus_name ? item.syllabus_name : '-'}} 
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="px-2">
                          {{item.grade_name ? item.grade_name : '-'}} 
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="px-2">
                          {{item.score_name ? item.score_name : '-'}} 
                      </span>
                    </td>
                  </tr>
                  <tr v-if="online_exam_reports.length == 0">
                      <td colspan="12" class="text-center">
                          No items found
                      </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="online_exam_reports.length > 0"
                  class="pull-right mt-7"
                  @input="getOnlineExamReports"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
  </v-app>    
</template>

<script>
import OnlineExamReportService from "@/services/report/online-exam/OnlineExamReportService"
import ScoreService from "@/services/score/score/ScoreService"

const online_exam_report = new OnlineExamReportService();
const score = new ScoreService();

export default{
  data(){
      return{
          total: null,
          perPage: null,
          page: null,
          online_exam_reports: [],
          loading: true,
          search:{
            name: '',
            start_date: '',
            end_date: '',
            score_id: null
          },
          menu: false,
          menuEndDate: false,
          scores:[],
          currentUser: {},
          isExporting: false,
          isExportingExcel: false,
          months: [],
      }
  },
  methods:{
      searchOnlineExamReports(){
        this.getOnlineExamReports();
      },
      getOnlineExamReports(){
        this.loading = true;
        
        online_exam_report
        .paginate(this.search, this.page, this.type)
        .then((response) => {
            this.online_exam_reports=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {

        })
        .finally(() => {
            this.loading = false;
        })
      },
      getScores(){
          score
          .all()
          .then((res) => {
              this.scores = res.data.scores;
          })
          .catch((err) => {

          });
      },
      getCurrentUser() {
        this.currentUser = this.$store.getters.currentUser;
        if(this.currentUser.access_type =='federal'){
            this.getScores();
        }
      },
      getMonths(){
        online_exam_report
        .getMonths()
        .then((res) => {
          this.months = res.data;
        })
        .catch((err) => {

        });
      },
      exportToCsv(){
        this.isExporting = true;
        online_exam_report
        .exportToCsv(this.search)
        .then((res) => {
          this.isExporting = false;
          this.$snotify.success('Export has been started. Once completed you can view  on notification.');
        })
        .catch((err) => {
          this.isExporting = false;
          this.$snotify.error('Oops looks like something we went wrong.');
        })
      },
      exportToExcel(){
        this.isExportingExcel = true;
        online_exam_report
        .exportToExcel(this.search)
        .then((res) => {
          this.isExportingExcel = false;
          this.$snotify.success('Export has been started. Once completed you can view  on notification.');
        })
        .catch((err) => {
          this.isExportingExcel = false;
          this.$snotify.error('Oops looks like something we went wrong.');
        })
      }
  },
  mounted(){
    this.getOnlineExamReports();
    this.getCurrentUser();
    this.getMonths();
  },
  computed:{
    type() {
      return this.$route.params.type;
    },
  }
}
</script>
