import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class OnlineExamReportService
{
    #api = null;
    constructor() {
        this.#api = API_URL + 'admin/online-exams-report';
    }
    paginate(data={},index = null, type) {
        let url = `${this.#api}/${type}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getMonths(){
        let url = `${this.#api}/get-months`
        return apiService.get(url);
    }

    exportToCsv(data){
        let url = `${this.#api}/export/csv`;
        if (data != null && data != "") {
            let queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        return apiService.get(url);
    }

    exportToExcel(data){
        let url = `${this.#api}/export/excel`;
        if (data != null && data != "") {
            let queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        return apiService.get(url);
    }
}
